import responseService from "../../Services/responseService";
import config from "../../config";
import Cookies from 'js-cookie';

class AuthState {

    isError=false
    message

    async login(login, password){
        const response = await responseService.responseApiServer(config.URL_LOGIN,{username:login.toLowerCase(), password})

        if(response.warning){
            return response
            // this.isError = true
            // this.message = response.message
            // console.log(response.message)
        }
        else{
            this.isError = false
            const token = response.data.token
            Cookies.set('token', token, { expires: 365 })
            window.location = window.location.href.split("?")[0]
        }

    }
}
const authState = new AuthState()
export default  authState