import React, {useEffect, useState} from 'react';
import GameState from "../../../../../State/GameState/GameState";
import GameCard from "./GameCard/GameCard";
import RedactGameFrom from "./RedactGameFrom/RedactGameFrom";
import LocationState from "../../../../../State/LocationState/LocationState";
import UserState from "../../../../../State/UserState/UserState";
import s from './RedactGameComponent.module.css'

const RedactGameComponent = () => {
    const [games, setGames] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    const [redactGame, setRedactGame] = useState(null)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isRedactFormView, setIsRedactFormView] = useState(false)
    const [locations, setLocations] = useState([])
    const [users, setUsers] = useState([])

    const setValueFieldGame = (fieldName, value)=>{
        const game = {...redactGame}
        if(fieldName === 'locations'){
            // console.log('length',redactGame.locations.find(loc=> loc === value ))
            if(redactGame.locations.find(loc=> loc === value )){
                const newList =  redactGame.locations.filter(loc => loc !== value)
                game.locations = newList
            }else
                game.locations.push(value)
        }else{
            game[fieldName] = value
        }
        setRedactGame(game)
    }

    const removeGame = async (id)=>{
        const result = await GameState.removeGame(id)
        if(result.warning){
            setIsError(true)
            setErrorMessage(result.message)
            return false
        }else{
            setTimeout(()=>{
                const newGameList = games.filter(g=>g.id!==id)
                setGames(newGameList)
            },1500)

            return true
        }
    }
    const saveGame = async ()=>{
        const isNew = !redactGame.id
        if(isNew){
            const result = await GameState.createGame(redactGame)
            if(result.warning){
                setIsError(true)
                setErrorMessage(result.message)

            }else {
                const newGameList = [...games]
                newGameList.push(result.data.game)
                setGames(newGameList)

            }
            return result
        }else{
            const result = await GameState.updateGame(redactGame)
            if(result.warning){
                setIsError(true)
                setErrorMessage(result.message)
            }else {
                const newGameList = []
                games.forEach(game=>{
                    if(game.id === redactGame.id)
                        newGameList.push(redactGame)
                    else
                        newGameList.push(game)
                })
                setGames(newGameList)

            }
            return result
        }
    }

    const init = async ()=>{
        const result = await GameState.getAllLGame()
        if(result.warning){
            setErrorMessage(result.message)
            setIsError(true)
        }else {
            setGames(result.data.games)
        }

        const resultLocation = await LocationState.getAllLLocations()
        if(resultLocation.warning){
            setErrorMessage(resultLocation.message)
            setIsError(true)
        }else {
            setLocations(resultLocation.data.locations)
        }

        const resultUser = await UserState.getAllUsers()
        if(resultUser.warning){
            setErrorMessage(resultUser.message)
            setIsError(true)
        }else {
            setUsers(resultUser.data.users)
        }

    }

    useEffect(()=>{
        init()
    },[])
    if (isRedactFormView)
        return (
            <div>
                <RedactGameFrom game={redactGame}
                                save={saveGame}
                                locations={locations}
                                setValueField={setValueFieldGame}
                                close={()=>setIsRedactFormView(false)}/>
            </div>
        )
    else
        return (
            <div className={s.wrapper}>
                <div className={s.title}>Список игр</div>
                {
                    games.map(game=>
                        <GameCard key={'game_list_'+game.id}
                                  redact={()=>{
                                      setRedactGame(game)
                                      setIsRedactFormView(true)
                                  }}
                                  remove={removeGame}
                                  game={game}/>
                    )
                }
                <div className={s.wrapper_button}>
                    <div onClick={()=>{
                        setRedactGame(GameState.getEmptyGame())
                        setIsRedactFormView(true)
                    }} className={'btn_add'}>Добавить игру</div>
                </div>
            </div>
        );
    };

export default RedactGameComponent;