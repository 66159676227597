
const config = {

    // SERVER_SOCKET:'ws://127.0.0.1:8100',
    SERVER_SOCKET:'wss://quizserver.vityazgroup.ru:8520',
    // URL_API:'http://127.0.0.1:8020/api/',
   URL_API:'https://quizserver.vityazgroup.ru:8520/api/',


    URL_LOGIN:'authorization/login',

    URL_GET_ALL_USERS:'user/get_all_users',
    URL_REMOVE_USER:'user/remove_user',
    URL_CREATE_USER:'user/create_user',
    URL_UPDATE_USER:'user/update_user',
    URL_CHANGE_USER_PASSWORD:'user/change_user_password',

    URL_GET_LOCATIONS:'location/get_locations',
    URL_REMOVE_LOCATION:'location/remove_location',
    URL_CREATE_LOCATION:'location/create_location',
    URL_UPDATE_LOCATION:'location/update_location',

    URL_GET_GAMES:'game/get_games',
    URL_REMOVE_GAME:'game/remove_game',
    URL_CREATE_GAME:'game/create_game',
    URL_UPDATE_GAME:'game/update_game',

}

export default config

