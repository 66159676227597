import s from './LocationCard.module.css'

import React, {useState} from 'react';

const LocationCard = ({location, redact, remove, users}) => {
    const [isDelete, setIsDelete] = useState(false)
    console.log(location)
    return (
        <div className={s.wrapper}>
            <div>Название: <span  className={s.name}>{location.name}</span></div>
            {location.description?<div>Описание: {location.description}</div>:<></>}
            <div>Тип: <span  className={s.name}>{location.type==='alone'?'одиночное':'парное'}</span></div>
            <div>Игротехник: {location.user && users.find(u=>u.id===location.user)?<span className={s.name}>{users.find(u=>u.id===location.user).username}</span>:'Не назначен'}</div>

            {isDelete?
                <div>Локация удалена</div>:
                <>
                    <div onClick={ ()=>{
                        redact(location.id)}
                                } className={'btn_redact'}>Редактировать</div>
                    <div onClick={async()=>{
                        if (window.confirm(`Удалить ${location.name}?`)){
                            const isResult = await remove(location.id)
                            if (isResult){
                                setIsDelete(true)
                            }
                        }
                    }} className={'btn_remove'}>Удалить</div>
                </>
            }
        </div>
    );
};

export default LocationCard;