import React, {useEffect, useState} from 'react';
import AdminPlacePage from "../../AdminPage/AdminPlacePage";
import GameInfo from "./GameInfo/GameInfo";
import ScorePage from "./ScorePage/ScorePage";
import s from './AdminGamePage.module.css'

import settingSvg from '../../../resources/settings.svg'
import playSvg from '../../../resources/play.svg'
import exitSvg from '../../../resources/exit.svg'

const AdminGamePage = ({games, infoGame, startGame, restoreGame, isOnline, sendMessageChat}) => {
    const [isSettingView,setIsSettingView] = useState(false)
    const [activeGame, setActiveGame] = useState(null)
    const [message, setMessage] = useState('')
    useEffect(()=>{
        if (!isOnline)
            setActiveGame(null)
    },[isOnline])
    if (isSettingView)
        return (
            <>
                {isOnline?<div className={s.online}></div>:<div  className={s.offline}></div>}
                {/*<div onClick={()=>setIsSettingView(false)} className={'btn_remove'}>Закрыть настройки</div>*/}
                <div className={s.icon_wrapper}>
                    <img  onClick={()=>setIsSettingView(false)} className={s.setting_play} src={playSvg} alt=""/>
                </div>
                <AdminPlacePage />
            </>
        )
    else if(activeGame)
        return (
            <>
                {isOnline?<div className={s.online}></div>:<div  className={s.offline}></div>}
                {/*<div className={'btn_remove'} onClick={()=>setActiveGame(null)}>Выйти</div>*/}
                <div className={s.icon_wrapper}><img onClick={()=>setActiveGame(null)}  className={s.setting} src={exitSvg} alt=""/></div>
                <ScorePage sendMessageChat={sendMessageChat} infoGame={infoGame} game={activeGame}/>
            </>
        )
    else
        return (
            <div>
                {isOnline?<div className={s.online}></div>:<div  className={s.offline}></div>}
                {/*<div onClick={()=>setIsSettingView(true)} className={'btn_redact'}>Перейти в настройки</div>*/}
                <div className={s.icon_wrapper}><img  onClick={()=>setIsSettingView(true)} className={s.setting} src={settingSvg} alt=""/></div>
                {games.map(game=>{
                    return(
                        <div className={s.game_card} key={'game_'+game.id}>
                            <div className={s.game_info_wrapper}>
                                <GameInfo game={game}/>
                            </div>

                            <div className={s.btn_wrapper}>
                                <div className={'btn_add'} onClick={()=> {
                                    setActiveGame(game)
                                    startGame(game.id)
                                }}>Запустить</div>
                                <div className={'btn_remove'} onClick={()=> {
                                    if (window.confirm('Сбросить данные?')){
                                        if(restoreGame(game.id)){
                                            setMessage('Данные сброшены')
                                            setTimeout(()=>{setMessage('')}, 1200)
                                        }
                                    }

                                }}>Сбросить данные</div>

                            </div>
                            <div className={s.message}>{message}</div>
                        </div>
                    )
                })}
            </div>
        );
};

export default AdminGamePage;