import React, {useEffect, useState} from 'react';
import LogView from "./LogView/LogView";
import s from './ScorePage.module.css'
import UserChat from "../../UserGamePage/MapPage/UserChat/UserChat";

const ScorePage = ({game,infoGame, sendMessageChat}) => {

    const [score, setScore]= useState([])
    const [winnerTable, setWinnerTable]= useState([])
    const [isViewLogs, setIsViewLogs] = useState(false)
    const [viewActiveLog, setViewActiveLog] = useState(false)

    useEffect(()=>{
        if (!infoGame)
            return
        const newScore = []
        const newWinnerTable = []
        for(let i=0;i<game.countTeams;i++){
            if(infoGame.scores && infoGame.scores['t'+i] &&  infoGame.scores['t'+i].score)
                newWinnerTable.push({team:'Команда '+(i+1), score:infoGame.scores['t' + i].score})
            else
                newWinnerTable.push({team:'Команда '+(i+1), score:0})
            for (let j=0;j<game.countRound;j++){
                if(!newScore[i])
                    newScore[i] = []
                if (infoGame.scores && infoGame.scores['t'+i]){
                    newScore[i][j] = infoGame.scores['t'+i][j]
                }

                else
                    newScore[i][j] = ['-']
            }
        }
        newWinnerTable.sort((a,b)=>b.score - a.score)
        setWinnerTable(newWinnerTable)
        // console.log('newScore',newScore)
        setScore(newScore)

    },[infoGame])
    if (score.length>0)
    return (
        <div className={s.wrapepr}>
            <div className={s.title}>Название игры: {game.name}</div>
            <table className={s.table_score}>
                <tbody>
                <tr><th>-</th>
                    {score[0].map((s,i)=>{

                        return(
                            <th key={'th'+i}>раунд {i+1}</th>
                        )
                    })}
                </tr>
                {score.map((t,i)=>{return(
                    <tr key={'row'+i}>
                        <td className={s.table_team_name}>Команда {i+1}</td>
                        {t.map((r,i2)=>{
                            if (r)
                                return(
                                <td key={'td'+i+i2}>{r && r.join(' / ')? r.join(' / '):r}</td>
                                )
                            else
                            return(
                            <td key={'td'+i+i2}>{'-'}</td>
                            )
                        }
                        )}
                    </tr>
                )})}
                </tbody>
            </table>

            <table className={s.table_score}>
                <caption>Рейтинг команд</caption>
                <tbody>
                {winnerTable.map((w,i)=>{
                    return(
                        <tr key={'row_winner'+i}>
                            <td className={s.table_team_name}>{w.team}</td>
                            <td className={s.table_team_name}>{w.score}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>

            {/*<UserChat sendMessage={sendMessageChat} infoGame={infoGame}/>*/}

            {/*<div className={s.wrapper_chat}>*/}
            {/*    <div>Чат</div>*/}
            {/*    <div>*/}
            {/*        <input type='text' value={chatMessage} placeholder={'текст сообщения'} onChange={e=>setChatMessage(e.target.value)}/>*/}
            {/*        <div onClick={()=>sendMessageChat(chatMessage)} className={'btn_add'}>Отправить</div>*/}
            {/*    </div>*/}
            {/*    {infoGame.chat.map((c,i)=>{*/}
            {/*        return(*/}
            {/*                <ChatCard key={'chat_'+i} chat={c}/>*/}
            {/*            )*/}

            {/*    })}*/}
            {/*</div>*/}

            <div onClick={()=>setIsViewLogs(!isViewLogs)} className={'btn_add'}>{isViewLogs?'скрыть':'показать'} логи</div>
            {isViewLogs?
            <div>
                <div className={s.wrapper_checkbox}>
                    <input className={s.checkbox} type="checkbox" checked={viewActiveLog} onChange={e=>setViewActiveLog(e.target.checked)}/>
                    <label onClick={()=>setViewActiveLog(!viewActiveLog)}>Показывать только активные</label>
                </div>

                <div className={s.wrapper_log}>
                    {infoGame.logs.map((log,i)=>{
                        if(!viewActiveLog ||(viewActiveLog && log.isActive))
                        return(
                            <LogView key={'log_'+i} log={log}/>
                        )
                    })}
                </div>
            </div>:
                <></>
            }
        </div>
    );
    else
        return (
            <div>Загрузка</div>
        )

};

export default ScorePage;