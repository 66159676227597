import React, {useState} from 'react';
import s from './LocationRedactForm.module.css'
import UserInfo from "../UserInfo/UserInfo";

const LocationRedactForm = ({location, fieldRedact, save, closeForm, users}) => {

    const [isViewUsers, setIsViewUsers] = useState(false)
    const [message, setMessage] = useState('')
    const userSelect = users.find(u=>u.id === location.user)
    const isNew = !!location.id
    return (
        <div>
            {isNew?<div className={s.title}>Создать локацию</div>:<div className={s.title}>Редактирование локации</div>}

            <div className={s.next_menu}>Основные настройки</div>

            <div className={s.wrapper_input}>
                <div className={s.title_input}>Название локации</div>
                <input type="text" value={location.name}
                       onChange={(e)=>fieldRedact('name', e.target.value)}/>
            </div>

            <div className={s.wrapper_input}>
                <div className={s.title_input}>Описание</div>
                <input type="text" value={location.description}
                       onChange={(e)=>fieldRedact('description', e.target.value)}/>
            </div>

            <div className={s.wrapper_input}>
                <div className={s.title_input}>Тип испытания</div>
                <select className={s.select_type} defaultValue={'alone'} value={location.type} onChange={e=>fieldRedact('type', e.target.value)}>
                    <option value={'alone'}>Одиночное</option>
                    <option value={'double'}>Парное</option>
                </select>
            </div>

            <div className={s.next_menu}>Прикрепленный игротехник</div>

            <div className={s.select_user}>
                <UserInfo user={userSelect}/>
            </div>

            <div onClick={()=>setIsViewUsers(!isViewUsers)} className={s.btn_view}>{isViewUsers?'Скрыть':'Показать'} пользователей</div>

            {isViewUsers?
                <div>
                    {users.map((user)=>{
                        if(user.role === 'user')
                        return(
                            <div onClick={()=> fieldRedact('user', user.id)}
                                 className={user.id === location.user?s.wrapper_user_list_active:s.wrapper_user_list}
                                 key={'use_card_location_'+user.id}>
                                <div className={s.radio_user}></div>
                                <UserInfo user={user}/>
                            </div>
                        )
                    })}
                </div>:
                <></>
            }

            <div className={'btn_save'} onClick={async ()=> {
                setMessage(await save())
                setTimeout(()=>{setMessage('')},1200)
            }}>Сохранить</div>
            <div className={'btn_remove'} onClick={closeForm}>Закрыть</div>
            <div className={s.message}>{message}</div>

        </div>
    );
};

export default LocationRedactForm;