import React from 'react';
import s from './LocationInfo.module.css'

const LocationInfo = ({location}) => {
    return (
        <div>
            <div>{location.name}</div>
            <div>{location.description}</div>
            <div>{location.type === 'alone'? 'Одиночная':'Парная'}</div>
            <div className={location.user?s.user_active:s.user_not_active}>{location.user?'Пользователь назначен':'Пользователь не назначен'}</div>
        </div>
    );
};

export default LocationInfo;