import responseService from "../../Services/responseService";
import config from "../../config";
import LocationClass from "./LocationClass";

class LocationState{
    async getAllLLocations(){
        const result = await responseService.responseApiServer(config.URL_GET_LOCATIONS)
        return result
    }

    async removeLocation(locationId){
        const result = await responseService.responseApiServer(config.URL_REMOVE_LOCATION, {id:locationId})
        return result
    }

    async createLocation(location){
        const result = await responseService.responseApiServer(config.URL_CREATE_LOCATION, {location})
        return result
    }

    async updateLocation(location){
        const result = await responseService.responseApiServer(config.URL_UPDATE_LOCATION, {location})
        return result
    }

    getEmptyLocation(){
        return new LocationClass()
    }

}

export default new LocationState()