import React, {useEffect, useState} from 'react';
import MapPage from "./MapPage/MapPage";
import UserChat from "./MapPage/UserChat/UserChat";
import s from './UserGamePage.module.css'

const UserGamePage = ({ maps, games, startGame, isOnline, report, infoGame, uncheckScoreReport, sendMessageChat}) => {
    const [gameList, setGameList] = useState([])
    const [game, setGame] = useState(null)
    const [map, setMap] = useState(null)
    const [isError, setIsError] = useState(false)
    const [message,  setMessage] = useState('')

    useEffect(()=>{
        try{
            const gl = []
            for(let i in maps){
                gl.push({game:games.find(g=>g.id === i), maps:maps[i]})
            }

            setGameList(gl)
            if (gl.length === 0){
                setIsError(true)
                setMessage('Нет доступных игр')
                return
            }
            if (gl.length === 1){
                startGame(gl[0].game.id)
                setMap(gl[0].maps)
                setGame(gl[0].game)

            }
        }
        catch (e){
            setIsError(true)
            setMessage('Ошибка в маршрутном листе')
        }


    }, [maps])

    if (isError)
        return <div className={s.error_message}>{message}</div>

    if(game && map)
        return (
            <div>
                <MapPage uncheckScoreReport={uncheckScoreReport} logs={infoGame && infoGame.logs?infoGame.logs:[]} isOnline={isOnline} game={game} map={map} report={report}/>
                {/*<UserChat infoGame={infoGame} sendMessage={sendMessageChat} />*/}
            </div>
        )
    else
    return (
        <div>
            <div>Доступные игры</div>
            {gameList.map(g=>{
                if (g.game)
                return(
                    <div key={'game_list_'+g.game.id}>{g.game.name?g.game.name:'Название не указано'}</div>
                )
            })}
        </div>
    );
};

export default UserGamePage;