class GameClass{

    //id
    name = ''
    description = ''
    locations = []
    isActive = true
    countTeams = 1
    countRound = 1
    map = new Map()

    roundPairScoreWin = 3
    roundPairScoreLose = 0
    roundPairScoreNone = 1
    roundAloneScoreWin = 2
    roundAloneScoreLose = 0
}

export default GameClass