import React, {useState} from 'react';
import s from './GameCard.module.css'

const GameCard = ({game, redact, remove}) => {
    const [isDel, setIsDel]= useState(false)
    return (
        <div className={s.wrapper}>
            <div className={s.name}>{game.name}</div>
            <div>{game.description}</div>
            {   isDel?
                <div className={s.delete_text}>Игра удалена</div>:
                <>
                <div onClick={redact} className={'btn_redact'}>Редактировать</div>
                <div onClick={async ()=>{
                    if(window.confirm(`Удалить игру ${game.name}?`))
                        setIsDel(await remove(game.id))
            }} className={'btn_remove'}>Удалить</div>
                </>
                }
        </div>
    );
};

export default GameCard;