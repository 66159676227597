class LocationClass{

    //id
    name = ''
    description = ''
    user=''
    locations = []
    type = 'alone'
    isActive = true

    // constructor(model) {
    //     this.id = model.id?model.id.toString():''
    //     this.name = model.name;
    //     this.Description = model.Description
    //     this.location = model.location;
    //     this.type = model.type;
    //     this.isActive = model.isActive;
    // }

}

export default LocationClass