import React, {useState} from 'react';
import ConstructorNavComponent from "./ConstructorNavComponent/ConstructorNavComponent";
import RedactUserComponent from "./RedactUserComponent/RedactUserComponent";
import RedactGameComponent from "./RedactGameComponent/RedactGameComponent";
import RedactLocationComponent from "./RedactLocationComponent/RedactLocationComponent";

const ConstructorComponent = () => {
    const constructorPageList = [
        {page:'user', name:'Пользователи'},
        {page:'location', name: 'Локации'},
        {page:'game', name:'Игры'},
    ]
    const [pageConstructor, setPageConstructor] = useState(constructorPageList[0].page)
    switch (pageConstructor){
        case 'user':
            return(<>
                    <ConstructorNavComponent constructorNavList={constructorPageList}
                                             constructorPage={pageConstructor}
                                             setConstructorPage={setPageConstructor}/>
                    <RedactUserComponent />
                </>
            )
        case 'game':
            return(
                <>
                    <ConstructorNavComponent constructorNavList={constructorPageList}
                                         constructorPage={pageConstructor}
                                         setConstructorPage={setPageConstructor}/>
                    <RedactGameComponent/>
                </>
            )
        case 'location':
            return(
                <>
                    <ConstructorNavComponent constructorNavList={constructorPageList}
                                         constructorPage={pageConstructor}
                                         setConstructorPage={setPageConstructor}/>
                    <RedactLocationComponent />
                </>
            )
        default:
            return(
                <ConstructorNavComponent constructorNavList={constructorPageList}
                                         constructorPage={pageConstructor}
                                         setConstructorPage={setPageConstructor}/>
            )
    }
};

export default ConstructorComponent;