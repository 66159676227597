import React, {useState} from 'react';
import QRCode from 'qrcode.react';
import s from './UserCardComponent.module.css'

const UserCardComponent = ({user, removeUser, startRedactUser, changePassword}) => {
    const [newPassword, setNewPassword] = useState("")
    const [isSetPassword, setIsPassword] = useState(false)

    const downloadQRCode = (userId, username) => {
        const qrCodeURL = document.getElementById('qrCodeEl_'+userId)
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        console.log(qrCodeURL)
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = `${username}_QR.png`
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }

    return (
        <div className={s.wrapper}>
            <div className={user.role==='admin'?s.type_admin:s.type_user}>{user.role==='admin'?'администратор':'игротехник'}</div>
            <div className={s.login}>Логин: {user.username}</div>
                <div className={s.string_name}>{user.stringName}</div>



            {/*<div>{user.isActive? "активен": "не активен"}</div>*/}
            {user.isDelete
                ?
                <div>Пользователь удален</div>
                :
                <>
                    <div className={'btn_redact'} onClick={()=>startRedactUser(user)}>Редактировать</div>
                    <div className={'btn_remove'} onClick={()=> {
                        if (window.confirm(`Удалить пользователя ${user.username}?`))
                        removeUser(user.id)
                    }}>Удалить</div>
                    <div className={'btn_update'} onClick={()=>setIsPassword(!isSetPassword)}>Сменить пароль</div>
                    {isSetPassword?
                        <div className={s.new_password_form}>
                            <input type="text" onChange={e=>setNewPassword(e.target.value)} value={newPassword} placeholder={"Новый пароль"}/>
                            <div >
                                <div className={'btn_save'} onClick={()=>changePassword(user.id, newPassword)}>сохранить</div>
                                <div className={'btn_remove'} onClick={()=> {
                                    setIsPassword(false)
                                    setNewPassword("")
                                }}>отмена</div>
                            </div>

                        </div>:
                        <></>
                    }
                    <div className={s.qr_wrapper}>
                        <QRCode
                            id={"qrCodeEl_"+user.id}
                            size={100}
                            value={`${window.location.origin}/?login=${user.username}&password=${user.password}`}
                        />
                        <div
                            className="btn_add"
                            onClick={()=>downloadQRCode(user.id, user.username)}
                        >Скачать QR</div>
                    </div>

                </>
            }

        </div>
    );
};

export default UserCardComponent;