import React from 'react';
import s from './GameInfo.module.css'

const GameInfo = ({game}) => {
    return (
        <div>
            <div className={s.name}>{game.name}</div>
            <div className={s.description}>{game.description}</div>
        </div>
    );
};

export default GameInfo;