import React, {useEffect, useState} from 'react';
import s from './MapRedact.module.css'

const MapRedact = ({countTeams, countRounds, map, locations, save, game}) => {
    const [currentMap, setCurrentMap] = useState(new Map())
    const [currentTeamNumber, setCurrentTeamNumber] = useState(-1)
    const [countLocationRound, setCountLocationRound] = useState([0])

    const teamsNumber = []

    for(let i=0; i<countTeams; i++)
        teamsNumber.push(i)

    const redactMap = (currentMap,countLocationRound)=>{
        const newMap = new Map()
        for(let i=0; i<countTeams; i++){
                newMap['t'+i] = []
            for( let j=0; j<countRounds; j++){
                  newMap['t'+i][j] = []
                for( let x=0; x<countLocationRound.length; x++){
                        if(!(currentMap['t'+i] && currentMap['t'+i][j] && currentMap['t'+i][j][x]))
                            newMap['t'+i][j].push('none')
                        else
                            newMap['t'+i][j].push(currentMap['t'+i][j][x])
                }
            }
        }

        setCurrentMap(newMap)

    }

    useEffect(()=>{
        const newBox = []
        if (map['t0'] && map['t0'][0])
            for(let i=0; i< map['t0'][0].length; i++){
                newBox.push(i)
            }
        else
            newBox.push(0)
        setCountLocationRound(newBox)
        if (countTeams>0)
            setCurrentTeamNumber(0)
        redactMap(map, newBox)

       //countLocationRound

        // setCurrentLocationList(newMap['t'+currentTeamNumber])
    },[])

    const onChangeMap = (team, round, index, value)=>{
        if (!  currentMap[team])
            currentMap[team] = []
        if (!  currentMap[team][round])
            currentMap[team][round]= []
        if (!  currentMap[team][round][index])
            currentMap[team][round][index] = []
        currentMap[team][round][index] = value

        setCurrentMap({...currentMap})

    }

    const teamsRow = []
    const roundsRow = []
    for(let i=0; i<countTeams; i++ )
        teamsRow.push(i)
    for(let i=0; i<countRounds; i++ )
        roundsRow.push(i)
    if(currentTeamNumber<0)
        return (<div>Не указано количество команд</div>)
    else
        return (
        <div className={s.wrapper}>
            <select className={s.select_user}
                    name="teamsNumber"
                    onChange={(e)=>setCurrentTeamNumber(e.target.value)}
                    defaultValue={-1} value={currentTeamNumber}>
                {teamsNumber.map(t=>{
                    return(
                        <option key={'team_List_'+t} value={t}>Команда {(t+1)}</option>
                )})}
            </select>
            <div className={s.team_list_wrapper}>
            {teamsNumber.map(t=>{
                return(
                    <div  className={t === currentTeamNumber?s.team_list_item_active: s.team_list_item} key={'team_req_'+t} onClick={()=>setCurrentTeamNumber(t)}>Команда {(t+1)}</div>
                )})}
            </div>

            <div className={s.title_team}>Редактируется: <span>Команда {Number(currentTeamNumber)+1}</span></div>

            <table className={s.table_map}>
                <tbody>
                <tr>
                    <th>Раунды</th>
                    {countLocationRound.map(t=><th key={'team_name'+t}>локация {countLocationRound.length === 1?'': t+1}</th>)}
                </tr>
                {roundsRow.map(round=>{
                    return(
                        <tr key={'row_'+round}>
                            <td className={s.round_name} key={'co_'+round+'-1'}>{'Раунд '+ (round+1)}</td>
                            {countLocationRound.map((loc)=>{
                                return(
                                    <td key={'co_'+round+loc}>
                                        <select defaultValue={'none'}
                                                onChange={e=>{
                                                    onChangeMap('t'+currentTeamNumber, round, loc, e.target.value)
                                                }}
                                                value={
                                                    currentMap['t'+currentTeamNumber] &&
                                                    currentMap['t'+currentTeamNumber][round] &&
                                                    currentMap['t'+currentTeamNumber][round][loc]?
                                                    currentMap['t'+currentTeamNumber][round][loc]:'none'}>
                                            <option value={'none'}>не указано</option>
                                            {locations.map(location=>{
                                                if (game.locations && game.locations.find(loc=>loc === location.id))
                                                return(
                                                    <option value={location.id} key={location.id+loc}>{location.name} / {location.type==='alone'?'одиночное':'парное'}</option>
                                                )
                                            })}
                                        </select>
                                        {/*{'t'+currentTeamNumber +'_'+ round+'_'+loc}*/}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <div>
                <div className={s.btn_add_location}
                    onClick={()=>{
                    setCountLocationRound([...countLocationRound, countLocationRound.length])
                    redactMap(currentMap, [...countLocationRound, countLocationRound.length])
                }}>Добавить столбец</div>
                {countLocationRound.length>1?
                    <div
                        className={s.btn_rem_location}
                        onClick={()=>{
                        countLocationRound.pop()
                        setCountLocationRound([...countLocationRound])
                        redactMap(currentMap,[...countLocationRound])
                    }}>Убрать столбец</div>:
                    <></>
                }
            </div>
            <div onClick={()=>{
                save(currentMap)
            }} className={'btn_update'}>Подтвердить</div>
        </div>
        );
};

export default MapRedact;