import React, {useState} from 'react';
import s from './PairTask.module.css'

const PairTask = ({location}) => {
    console.log('location',location)
    return (
        <div>
            <div className={s.round}>
                {
                    location.isAlone?
                        <div className={s.round_number}>{location.round+1}</div>:
                        <div className={s.round_number}>{location.round+1}-{location.round+2}</div>
                }

                <div className={s.round_word}>{location.isAlone?'раунд':'раунды'}</div>
            </div>
            <div className={s.team}>Команда {Number(location.team1.substring(1))+1} - Команда {Number(location.team2.substring(1))+1}</div>
            <div>{location.location.name}</div>
            <div>{location.location.type==='alone'?'одиночное':'парное'}</div>

        </div>
    );
};

export default PairTask;