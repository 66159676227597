import React, {useEffect, useState} from 'react';
import LocationState from "../../../../../State/LocationState/LocationState";
import LocationCard from "./LocationCard/LocationCard";
import s from './RedactLocationComponent.module.css'
import LocationRedactForm from "./LocationRedactForm/LocationRedactForm";
import UserState from "../../../../../State/UserState/UserState";

const RedactLocationComponent = () => {
    const [locations, setLocations] = useState([])
    const [isLoad, setIsLoad] = useState(true)
    const [redactLocation, setRedactLocation] = useState(null)
    const [isRedactViewForm, setIsRedactViewForm] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [users, setUsers] = useState([])

    const setError = (message)=>{
        setIsError(true)
        setErrorMessage(message)
    }

    const closeErrorForm = ()=>{
        setIsError(false)
        setErrorMessage('')
    }

    const init = async ()=>{
        setIsLoad(false)
        const result = await LocationState.getAllLLocations()
        if(result.warning){
          setError(result.warning)
        }else{
            setLocations(result.data.locations)
        }
        const resultUsers = await UserState.getAllUsers()
        if(resultUsers.warning){
            setError(result.warning)
        }else{
            setUsers(resultUsers.data.users)
        }
        setIsLoad(true)
    }


    useEffect(()=>{
        init()
    },[])

    const closeRedactForm = ()=>{
        setIsRedactViewForm(false)
    }

    const changeFieldRedactLocation = (fieldName, value)=>{
        const newRedactLocation = {...redactLocation}
        newRedactLocation[fieldName] = value
        setRedactLocation(newRedactLocation)
    }

    const removeLocation = async (id)=>{
        const result = await LocationState.removeLocation(id)
        return !result.warning
    }

    const saveLocation = async ()=>{
        const isNew = !!redactLocation.id
        if(isNew){
            const result = await LocationState.updateLocation(redactLocation)
            if (result.warning){
                setError(result.message)
                return result.message
            }
            else{
                const newLocationList = []
                locations.forEach(location=>{
                    if(redactLocation.id === location.id)
                        newLocationList.push(redactLocation)
                    else
                        newLocationList.push(location)
                })
                setLocations(newLocationList)
                return "Локация сохранена"
            }
        }else{
            const result = await LocationState.createLocation(redactLocation)
            if (result.warning){
                setError(result.message)
                return result.message
            }
            else{
                const newLocationList = [...locations]
                newLocationList.push(result.data.location)
                setLocations(newLocationList)
                return "Локация сохранена"
            }
        }
    }


    if (isRedactViewForm)
        return (
            <div>
                <LocationRedactForm location={redactLocation}
                                    fieldRedact={changeFieldRedactLocation}
                                    users = {users}
                                    save={saveLocation}
                                    closeForm={closeRedactForm}/>
            </div>
        )
    else
    return (
        <div>
            <div className={s.title}>Список локаций</div>
            {locations.map((location)=>{
                return(
                    <LocationCard key={location.id}
                                  remove={removeLocation}
                                  users={users}
                                  redact={
                                      ()=>{
                                          setRedactLocation(location)
                                          setIsRedactViewForm(true)
                                      }
                                  }
                                  location={location}/>
                )
            })}
            <div className={s.btn_wrapper}>
                <div className={'btn_add'} onClick={()=>{
                    setRedactLocation(LocationState.getEmptyLocation())
                    setIsRedactViewForm(true)
                }}>Добавить локацию</div>
            </div>

        </div>
    );
};

export default RedactLocationComponent;