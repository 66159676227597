import React from 'react';
import s from './AloneTask.module.css'
const AloneTask = ({location}) => {
    console.log('locat', location)
    return (
        <div>
            <div className={s.round}>{location.round+1}</div>
            <div className={s.team}>Команда {Number(location.team.substring(1))+1}</div>
            <div className={s.name}>{location.location.name}</div>
            <div  className={s.type}>{location.location.type==='alone'?'одиночное':'парное'}</div>
        </div>
    );
};

export default AloneTask;