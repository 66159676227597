import React, {useEffect, useState} from 'react';
import config from "../../config";
import Cookies from "js-cookie";
import AdminPlacePage from "../AdminPage/AdminPlacePage";
import AdminGamePage from "./AdminGamePage/AdminGamePage";
import UserGamePage from "./UserGamePage/UserGamePage";
import s from './GamePage.module.css'
import Loader from "../Static/Loader/Loader";

const GamePage = () => {
    const [role,setRole] = useState('none')
    const [ws,setWs] = useState(null)
    const [games, setGames] = useState([])
    // const [activeGames, setActiveGames] = useState([])
    const [map, setMap] = useState(null)
    const [isOnline, setIsOnline] = useState(false)
    const [infoGame, setInfoGame] = useState(null)
    const [isLoad, setIsLoad] = useState(false)

    const token = Cookies.get('token')

    const  reLoad = ()=>{
        Cookies.remove('token')
    }
    const restoreGame = (gameId)=>{
        ws.send(JSON.stringify({action:'reset', gameId}))
        return true
    }

    const startGame = (gameId)=>{
        ws.send(JSON.stringify({action:'start', gameId, token}))
        return true
    }

    const report = (report)=>{
        ws.send(JSON.stringify({action:'report', report}))
        return true
    }

    const sendMessageChat = (message)=>{
        ws.send(JSON.stringify({action:'chat', message}))
        return true
    }

    const uncheckScoreReport = (report)=>{
        ws.send(JSON.stringify({action:'deReport', report}))
        return true
    }

    const init =async ()=> {
        setIsLoad(false)
        if (!token){
            reLoad()
        }
        const ws = new WebSocket(config.SERVER_SOCKET)
        ws.onopen = function () {
            console.log("подключился");
            setIsOnline(true)
            ws.send(JSON.stringify({
                action: 'login',
                token: token,
            }));
        };
        ws.onclose = () => {
            setIsOnline(false)
            console.log('disconnect')
            init()


        }
        ws.onmessage = function (message) {
            const data = JSON.parse(message.data)
            console.log("Message", data);

            switch (data.action) {
                case 'login':
                    if(data.warning){
                        setRole('none')
                        reLoad()
                    }else{
                        setWs(ws)
                        setRole(data.user.role?data.user.role:'not_user')
                        setGames(data.games)
                        if(data.user.role === 'user'){
                            setMap(data.map)
                        }
                    }
                    setIsLoad(true)
                    break
                case 'infoGame':
                    setInfoGame(data.gameInfo)
            }
        }

    }

    useEffect(() => {
        init()
    }, [])

    if (!isLoad)
        return <Loader/>
    switch (role){
        case 'none':
            return (
                <div className={s.error_user}>
                    Сервер не доступен
                </div>
            );
            case 'not_user':
            return (
                <div className={s.error_user}>
                    Неизвестный пользователь
                </div>
            );
        case 'admin':
            return (
                <div>
                    <AdminGamePage sendMessageChat={sendMessageChat} restoreGame={restoreGame} isOnline={isOnline} infoGame={infoGame} ws={ws} games={games} startGame={startGame}/>
                </div>
            );
        case 'user':
            return (
                <div>
                    <UserGamePage sendMessageChat={sendMessageChat} uncheckScoreReport={uncheckScoreReport} isOnline={isOnline} infoGame={infoGame} games={games} maps={map} startGame={startGame} report={report}/>
                </div>
            )
    }

};

export default GamePage;