import React from 'react';
import s from './UserInfo.module.css'

const UserInfo = ({user}) => {
    if(user)
        return (
            <div className={s.wrapper_user_list}>
                <div>{user.username}</div>
                <div>{user.stringName}</div>
            </div>
        );
    else
        return (
            <div className={s.wrapper_user_list}>
               <div>Пользователь не выбран</div>
            </div>
        )
};

export default UserInfo;