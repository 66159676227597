class UserClass{
    // id
    username=''
    password=''
    role='user'
    isActive=true
    description=''
    stringName=''
    location=''
    game=''

}
 
export default UserClass;