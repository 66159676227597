import React from 'react';
import s from './LogView.module.css'

const LogView = ({log}) => {

    const date = new Date(log.date)
    console.log('log', log)
    return (
        <div className={log.isActive?s.wrapper_active:s.wrapper_not_active}>
            <div className={log.isActive?s.is_active:s.is_not_active}>{log.isActive?'v':'x'}</div>
            <div>раунд: {log.report.round+1}</div>
            <div>время: {date.getHours()}:{date.getMinutes()}</div>
            <div>Пользователь: {log.user.username}</div>
            <div>Тип задания: {log.report.typeTask==='alone'?'одиночное':'парное'}</div>
            {log.report.typeTask==='alone'?
                <div>Команда {Number(log.report.team.substring(1))+1} {log.report.status==='winner'?'победила':'проиграла'}</div>:
                <div>
                    {log.report.status === 'winner'?
                        <div>
                            <div>Команда {Number(log.report.teamWinner.substring(1))+1} победила</div>
                            <div>Команда {Number(log.report.teamLose.substring(1))+1} проиграла</div>
                        </div>:
                        <div></div>
                    }
                </div>
            }

        </div>
    );
};

export default LogView;