import './App.css';
import Cookies from "js-cookie";
import AuthPage from "./Pages/AuthPage/AuthPage";
import GamePage from "./Pages/GamePage/GamePage";

function App() {
  const token = Cookies.get('token')
    let login = (new URLSearchParams(window.location.search)).get("login")
    let password = (new URLSearchParams(window.location.search)).get("password")

    if (login && password){
        return (
            <>
                <div className={'header'}><span>VityazGroup</span></div>
                <AuthPage qrLogin={login} qrPassword={password}/>
            </>

        )
    }
  if(!token){
    return (
        <>
            <div className={'header'}><span>VityazGroup</span></div>
            <AuthPage/>
        </>

    )
  }
      return (
          <div className="App">
              <div className={'header'}><span>VityazGroup</span></div>
              <GamePage/>
          </div>
      );

}

export default App;
