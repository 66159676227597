import React, {useState} from 'react';
import s from './RedactGameFrom.module.css'
import LocationInfo from "../LocationInfo/LocationInfo";
import MapRedact from "./MapRedact/MapRedact";

const RedactGameFrom = ({game, save, setValueField, close, locations}) => {
    const [isViewLocation, setIsViewLocation] = useState(false)
    const [isViewMessage, setIsViewMessage] = useState(false)
    const [message, setMessage] = useState('')

    return (
        <div>
            <div className={s.title}>Редактирование игры</div>
                <div className={s.next_menu}>Основные настройки</div>
                <div className={s.wrapper_game}>
                    <div>Название</div>
                    <input type="text" value={game.name} placeholder={'название'} onChange={(e)=>{setValueField('name', e.target.value)}}/>
                </div>

                <div className={s.wrapper_game}>
                    <div>Описание</div>
                    <input type="text" value={game.description} placeholder={'описание'} onChange={(e)=>{setValueField('description', e.target.value)}}/>
                </div>

                <div className={s.wrapper_game}>
                    <div>Количество раундов</div>
                    <input type={'text'} value={game.countRound} placeholder={'количество раундов'}
                           onChange={(e)=>setValueField('countRound', e.target.value)} />
                </div>

                <div className={s.wrapper_game}>
                    <div>Количество команд</div>
                    <input type={'text'} value={game.countTeams} placeholder={'количество баллов'}
                           onChange={(e)=>setValueField('countTeams', e.target.value)} />
                </div>

            {/*roundPairScoreWin = 3*/}
            {/*roundPairScoreLose = 0*/}
            {/*roundPairScoreNone = 1*/}
            {/*RoundAloneScoreWin = 2*/}
            {/*RoundAloneScoreLose = 0*/}
            <div className={s.score_setting}>Настройка счета: баллы для одиночной игры</div>
            <div className={s.wrapper_game_score}>
                <div>Победа в одиночном</div>
                <input type={'text'} value={game.roundAloneScoreWin} placeholder={'количество баллов'}
                       onChange={(e)=>setValueField('roundAloneScoreWin', e.target.value)} />
            </div>
            <div className={s.wrapper_game_score}>
                <div>Проигрыш в одиночном</div>
                <input type={'text'} value={game.roundAloneScoreLose} placeholder={'количество баллов'}
                       onChange={(e)=>setValueField('roundAloneScoreLose', e.target.value)} />
            </div>

            <div className={s.score_setting}>Настройка счета: баллы для парной игры</div>
            <div className={s.wrapper_game_score}>
                <div>Победа в парном</div>
                <input type={'text'} value={game.roundPairScoreWin} placeholder={'количество баллов'}
                       onChange={(e)=>setValueField('roundPairScoreWin', e.target.value)} />
            </div>
            <div className={s.wrapper_game_score}>
                <div>Проигрыш в парном</div>
                <input type={'text'} value={game.roundPairScoreLose} placeholder={'количество баллов'}
                       onChange={(e)=>setValueField('roundPairScoreLose', e.target.value)} />
            </div>
            <div className={s.wrapper_game_score}>
                <div>Ничья</div>
                <input type={'text'} value={game.roundPairScoreNone} placeholder={'количество баллов'}
                       onChange={(e)=>setValueField('roundPairScoreNone', e.target.value)} />
            </div>


            <div className={s.next_menu}>Локации</div>
                {!game.locations || game.locations.length === 0?
                    <div className={s.select_location_title}>Локации не выбраны</div>:
                    <div className={s.select_location_title}>Выбрано {game.locations.length} локаци{game.locations.length>4?'й':'и'}</div>
                }
                <div className={s.btn_view_location}
                     onClick={()=>setIsViewLocation(!isViewLocation)}>{isViewLocation?'Скрыть':'Показать'} локации</div>
                {isViewLocation?
                        <div className={s.locations}>
                            {locations.map(location => {
                                const isActive = game.locations && game.locations.find(loc=>loc === location.id)
                                return(
                                    <div className={isActive?s.wrapper_location_active:s.wrapper_location}
                                         onClick={()=>setValueField('locations',location.id)}
                                         key={'location_form_game_'+location.id}>
                                        <LocationInfo location={location}/>
                                    </div>

                                    )
                            })}
                        </div>:
                        <></>
                }
                <div className={s.next_menu}>Маршруты</div>

                <MapRedact countTeams={game.countTeams}
                           countRounds={game.countRound}
                           locations={locations}
                           game={game}
                           save={(newMap)=>{
                               setValueField('map', newMap)
                           }}
                           map={game.map}/>

            <div className={s.next_menu}>{message}</div>
                <div onClick={async ()=>{
                    const result = await save()
                    if (result.warning)
                        setMessage(result.message?result.message:'Ошибка сервера')
                    else
                        setMessage('Игра сохранена')
                    setIsViewMessage(true)
                    setTimeout(()=>{
                        setIsViewMessage(false)
                        setMessage('')
                    }, 1200)
                }} className={'btn_save'}>Сохранить</div>
                <div onClick={close} className={'btn_remove'}>Закрыть</div>

        </div>
    );
};

export default RedactGameFrom;