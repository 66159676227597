import React, {useState} from 'react';
import s from './MapPage.module.css'
import AloneTask from "./AloneTask/AloneTask";
import PairTask from "./PairTask/PairTask";

const MapPage = ({map, game, isOnline, report, logs, uncheckScoreReport}) => {

    return (
        <div>
            {isOnline?<div className={s.online}></div>:<div  className={s.offline}></div>}
            <div className={s.title}>Название игры: {game.name}</div>
            {/*<div>Моя локация: {map[0].location.name}</div>*/}
            {/*<div>Тип локации: {map[0].location.type==='alone'?'одиночное':'парно'} испытание</div>*/}

            <div>
                {map.map((m,i)=>{
                    let isCheck = false
                    let isWinner = false
                    let teamWinner = ''

                    if(logs.length>0)
                    logs.forEach(log=>{
                        console.log('log', log.report)
                        if (m && log.isActive && m.round === log.report.round &&  m.location.user.id === log.user.id){
                            // console.log(log.report)
                            if (log.report.typeTask === 'alone'){
                                isWinner = log.report.status === "winner"
                            }
                            else{
                                isWinner = log.report.status === "winner"
                                if (isWinner)
                                    teamWinner = log.report.teamWinner
                            }
                            isCheck  = true
                        }
                    })
                    if(m && m.location && m.location.type === 'alone') {
                        if(!m.team){
                           return <div>Неверно заполнен маршрутный лист</div>
                        }
                        return (<div key={'location_alone_' + i} className={s.wrapper_game}>
                                <AloneTask logs={logs} location={m}/>
                                {isCheck ?
                                    <>
                                        <div
                                            className={isWinner ? s.winner_text : s.lose_text}>{isWinner ? 'Команда победила' : 'Команда проиграла'}</div>
                                        <div className={'btn_remove'} onClick={() => {
                                            if (window.confirm(`Отменить результат ${m.round + 1} раунда?`))
                                                uncheckScoreReport({typeTask: 'alone', round: m.round})
                                        }}>Отмена
                                        </div>
                                    </> :
                                    <div className={s.wrapper_btn}>
                                        <div onClick={() => {
                                                report({typeTask: 'alone', round: m.round, team: m.team, status: 'winner'})
                                            }} className={'btn_add'}>Победа
                                        </div>
                                        <div onClick={() => {
                                            report({typeTask: 'alone', round: m.round, team: m.team, status: 'lose'})
                                        }} className={'btn_remove'}>Поражение
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                    }
                    else
                        if (m) {
                            if(!m.team1 || !m.team2){
                                return <div>Неверно заполнен маршрутный лист</div>
                            }
                            return (
                                <div key={'location_double_' + i} className={s.wrapper_game}>
                                    <PairTask logs={logs} location={m}/>
                                    {isCheck ?
                                        <>
                                            {isWinner ?
                                                <div className={s.winner_text}>Победила
                                                    команда {Number(teamWinner.substring(1)) + 1}</div> :
                                                <div className={s.lose_text}>Ничья</div>
                                            }
                                            <div className={'btn_remove'} onClick={() => {
                                                if (window.confirm(`Отменить результат ${m.round + 1} раунда?`))
                                                    uncheckScoreReport({typeTask: 'alone', round: m.round})
                                            }}>Отмена
                                            </div>
                                        </> :
                                        <div className={s.wrapper_btn}>
                                            <div onClick={() => {
                                                report({
                                                    typeTask: 'double',
                                                    round: m.round,
                                                    teamWinner: m.team1,
                                                    teamLose: m.team2,
                                                    status: 'winner'
                                                })
                                            }} className={'btn_add'}>Победа К{Number(m.team1.substring(1)) + 1}</div>
                                            <div onClick={() => {
                                                report({
                                                    typeTask: 'double',
                                                    round: m.round,
                                                    team1: m.team1,
                                                    team2: m.team2,
                                                    status: 'none'
                                                })
                                            }} className={'btn_remove'}>Ничья
                                            </div>
                                            <div onClick={() => {
                                                report({
                                                    typeTask: 'double',
                                                    round: m.round,
                                                    teamWinner: m.team2,
                                                    teamLose: m.team1,
                                                    status: 'winner'
                                                })
                                            }} className={'btn_add'}>Победа К{Number(m.team2.substring(1)) + 1}</div>
                                            <div onClick={() => {
                                                report({
                                                    typeTask: 'double',
                                                    round: m.round,
                                                    team1: m.team1,
                                                    team2: m.team2,
                                                    status: 'double_lose'
                                                })
                                            }} className={'btn_remove'}>Команды проиграли
                                            </div>
                                        </div>
                                    }

                                </div>
                            )
                        }
                })}
            </div>
        </div>
    );
};

export default MapPage;